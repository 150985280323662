<template>
  <section :class="$style.brands">
    <div :class="$style.wrapper">
      <el-button
        type="primary"
        @click="
          $router.push(
            getRoute({
              route: $options.ADDSELLERS_ADMIN_ROUTES.BRANDS.CREATE,
            }),
          )
        "
      >
        Создать бренд
      </el-button>
    </div>
    <el-table :data="brands" stripe>
      <el-table-column
        prop="orderField"
        label="Приоритет"
        width="300"
      ></el-table-column>
      <el-table-column prop="images" label="Фото">
        <template slot-scope="scope">
          <img
            :src="
              scope.row.image
                ? $configData.s3_link + scope.row.image
                : '/img/gallery/default.jpg'
            "
            width="80"
            height="80"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Название"> </el-table-column>
      <el-table-column prop="moySkladName" label="Название в моем складе">
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="
              getRoute({
                route: $options.ADDSELLERS_ADMIN_ROUTES.BRANDS.UPDATE,
                params: { id: scope.row.id },
              })
            "
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="totalPages"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import { getRoute, ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  components: {
    ActionButtons,
  },
  ADDSELLERS_ADMIN_ROUTES,
  PROJECTS,
  data() {
    return {
      brands: [],
      limit: 10,
      page: 1,
      total: 0,
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
  },
  async created() {
    await this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        orderBy: 'order_field',
        orderDir: 'desc',
      }

      const { value, error } = await delivery.AddwineCore.BrandsActions.getList(
        query,
      )

      loading.close()

      if (error) return

      this.brands = value.data
      this.total = value.meta.count
    },
    async remove(data) {
      const isConfirm = confirm(`Вы точно хотите удалить ${data.name} ?`)
      if (isConfirm) {
        const { error } = await delivery.AddwineCore.BrandsActions.delete(
          data.id,
        )

        if (error) return

        await this.getList()
      }
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
  },
}
</script>

<style lang="scss" module>
.brands {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
  }
  .pagination {
    @include pagination;
  }
}
</style>
